import React from 'react'
import Layout from '../components/layout'

function index({...props}) {
  return (
    <Layout>
    
      <div className="hero">
        <img src="hero.png" width="100%" height="500px" alt="hero image" />
      </div>
      
      <div className="blog-posts">
        <div className="blog-posts__post">
          <h2>Rome food tour</h2>
          <img src="" alt="" />
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        </div>
        <div className="blog-posts__post">
          <h2>Where to find the besst waffle in Belgium</h2>
          <img src="" alt="" />
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        </div>
        <div className="blog-posts__post">
          <h2>Top 10 things to do in Sardinia</h2>
          <img src="" alt="" />
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        </div>
      </div>
      
      <div className="instagram">
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
        <img className="instagram__image" src="" alt="" />
      </div>
      
      <div className="about">
      <h2>The couple</h2>
      <img src="" alt="" />
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
      </div>
      
      <div className="footer">
      </div>

    </Layout>
  );
}

export default index;
